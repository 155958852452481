<template>
  <div id="biochip">
    <v-container>
      <div class="operate d-flex justify-space-between">
        <div class="d-flex">
          <div class="align-self-center">
            <v-btn @click="importSetting.dialog = true" color="primary"
              >文件导入</v-btn
            >
          </div>
        </div>
        <div class="search d-flex">
          <div>
            <div class="d-flex justify-end">
              <div class="align-self-center">
                <v-text-field
                  v-model="options.key"
                  label="请填写芯片编码"
                  required
                  clearable
                ></v-text-field>
              </div>

              <div class="date align-self-center ml-4">
                <v-menu
                  v-model="startTimeEnabled"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="options.startTime"
                      label="请填写开始时间"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="options.startTime"
                    @input="startTimeEnabled = false"
                  ></v-date-picker>
                </v-menu>
              </div>
              <div class="date align-self-center ml-4">
                <v-menu
                  v-model="endTimeEnabled"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="options.endTime"
                      label="请填写结束时间"
                      prepend-icon="mdi-calendar"
                      readonly
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="options.endTime"
                    @input="endTimeEnabled = false"
                  ></v-date-picker>
                </v-menu>
              </div>
            </div>
            <div class="d-flex justify-end">
              <div class="align-self-center ml-4">
                <v-text-field
                  v-model="options.peasantHouseholdKey"
                  label="请填写农户姓名、电话或身份证"
                  required
                  clearable
                  style="width: 280px"
                ></v-text-field>
              </div>
              <div class="align-self-center ml-4">
                <v-text-field
                  v-model="options.staffKey"
                  label="请填写员工账号或者姓名"
                  required
                  clearable
                  style="width: 230px"
                ></v-text-field>
              </div>
              <div class="align-self-center ml-4">
                <v-text-field
                  v-model="options.adminKey"
                  label="请填写管理员账号或者姓名"
                  required
                  clearable
                  style="width: 250px"
                ></v-text-field>
              </div>
            </div>
          </div>
          <div class="align-self-center ml-4">
            <div class="ma-4"><v-btn @click="load">搜索</v-btn></div>
            <div class="ma-4"><v-btn @click="exportExcel">导出</v-btn></div>
          </div>
        </div>
      </div>
      <v-alert
        outlined
        v-model="importSetting.result.show"
        :type="importSetting.result.success ? 'success' : 'error'"
        dismissible
      >
        {{ importSetting.result.message }}
      </v-alert>
      <div class="content">
        <v-data-table
          :headers="table.headers"
          :items="table.page.data"
          :loading="table.loading"
          hide-default-footer
        >
          <template v-slot:item.actions="{ item }">
            <v-btn color="error" x-small plain @click="deleteItem(item)">
              删除
            </v-btn>
          </template>
        </v-data-table>
        <div class="text-center pt-4">
          <v-pagination
            v-model="options.page"
            :length="table.page.pageCount"
          ></v-pagination>
        </div>
      </div>
    </v-container>
    <v-dialog v-model="table.deleteDialog" max-width="290">
      <v-card>
        <v-card-title class="headline"> 确认 </v-card-title>

        <v-card-text> 您确定要删除该项码？ </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="table.deleteDialog = false"> 取消 </v-btn>

          <v-btn color="error" @click="deleteOk"> 确定 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="exportSetting.dialog" max-width="350">
      <v-card>
        <v-card-title class="headline"> 导出 Excel</v-card-title>

        <v-card-text>
          <v-radio-group v-model="exportSetting.singleWorksheet">
            <v-radio :label="`每个农户使用单独工作簿`" :value="false"></v-radio>
            <v-radio
              :label="`所有农户使用同一个工作簿`"
              :value="true"
            ></v-radio>
          </v-radio-group>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn @click="exportSetting.dialog = false"> 取消 </v-btn>

          <v-btn color="error" @click="exportOk"> 导出 </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <div v-if="importSetting.dialog">
      <v-dialog v-model="importSetting.dialog" max-width="450">
        <v-card>
          <v-card-title class="headline"> 导入 Excel</v-card-title>
          <v-card-text>
            <v-form ref="importForm" v-model="importSetting.valid">
              <v-autocomplete
                v-model="importSetting.peasantHousehold.value"
                :loading="importSetting.peasantHousehold.loading"
                :items="importSetting.peasantHousehold.items"
                :search-input.sync="importSetting.peasantHousehold.search"
                :rules="importSetting.peasantHousehold.rules"
                cache-items
                dense
                filled
                label="请选择农户"
              ></v-autocomplete>
              <v-file-input
                label="请选择 Excel 文件"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                :rules="importSetting.file.rules"
                v-model="importSetting.file.file"
              ></v-file-input>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="importCancel"> 取消 </v-btn>
            <v-btn color="error" @click="importOk"> 导出 </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    load() {
      if (this.table.loading) return;
      this.table.loading = true;
      this.$shareyue.replaceRoute(this.options);
      this.$shareyueApi
        .getBiochips(this.options)
        .then((data) => {
          this.options.page = data.page + 1;
          data.data = data.data.map((p) => {
            p.peasantHousehold = "";
            if (p.peasantHouseholdName) {
              p.peasantHousehold = `${p.peasantHouseholdName}/${p.peasantHouseholdTelephone}/${p.peasantHouseholdIdCard}`;
            }
            p.account = "";
            if (p.staffAccount) {
              p.account = `${p.staffAccount}(员工)`;
            } else if (p.adminAccount) {
              p.account = `${p.adminAccount}(管理员)`;
            }
            return p;
          });
          this.table.page = data;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
    deleteItem(item) {
      this.table.deleteDialog = true;
      this.table.deleteItem = item;
    },
    deleteOk() {
      var index = this.table.page.data.find((p) => p == this.table.deleteItem);
      if (index != -1) {
        this.table.page.data.splice(index, 1);
        this.$shareyueApi.deleteBiochip(this.table.deleteItem.id);
      }
      this.table.deleteDialog = false;
    },
    exportExcel() {
      this.exportSetting.dialog = true;
    },
    exportOk() {
      this.exportSetting.dialog = false;
      this.$shareyueApi.exportBiochipExcel(
        Object.assign(
          {},
          {
            singleWorksheet: this.exportSetting.singleWorksheet,
          },
          this.options
        )
      );
    },
    importOk() {
      if (!this.$refs.importForm.validate()) return;
      this.importSetting.dialog = false;
      this.$shareyueApi
        .importBiochipExcel(this.importSetting.data)
        .then((p) => {
          let message = "导入成功";
          if (p.data.conflict.length) {
            message = `${message}；其中 ${p.data.conflict.join(
              "、"
            )}  已被使用`;
          }
          this.importSetting.result.message = message;
          this.importSetting.result.success = true;
          this.load();
        })
        .catch((p) => {
          let message = "导入失败";

          this.importSetting.result.message = message;
          this.importSetting.result.success = false;
        })
        .finally(() => {
          this.importSetting.result.show = true;
          this.importCancel();
        });
    },
    importCancel() {
      this.importSetting.dialog = false;
      this.importSetting.data.file = "";
      this.importSetting.data.peasantHouseholdId = null;
      this.importSetting.file.file = null;
      this.importSetting.peasantHousehold.value = null;
    },
    searchPeasantHousehold(val) {
      if (this.importSetting.peasantHousehold.loading) return;
      this.importSetting.peasantHousehold.loading = true;
      this.$shareyueApi
        .getPeasantHouseholds({
          key: val,
          page: 1,
          pageSize: 10,
        })
        .then((data) => {
          this.importSetting.peasantHousehold.data = data.data.map((p) => {
            let result = {
              id: p.id,
              name: `${p.name}(${p.idCard})`,
            };
            return result;
          });
          this.importSetting.peasantHousehold.items = this.importSetting.peasantHousehold.data.map(
            (p) => p.name
          );
        })
        .finally(() => {
          this.importSetting.peasantHousehold.loading = false;
        });
    },
  },
  watch: {
    "options.page"() {
      this.load();
    },
    "importSetting.file.file"() {
      //console.error(this.importSetting.file);
      if (this.importSetting.file.file) {
        this.$shareyue
          .uploadFile({
            files: [this.importSetting.file.file],
          })
          .then((res) => {
            this.importSetting.data.file = res.data[0];
          });
      } else {
        this.importSetting.data.file = null;
      }
    },
    "importSetting.peasantHousehold.search"(val) {
      val !== this.importSetting.peasantHousehold.value &&
        this.searchPeasantHousehold(val);
    },
    "importSetting.peasantHousehold.value"(val) {
      let data = this.importSetting.peasantHousehold.data.find(
        (p) => p.name == val
      );
      this.importSetting.data.peasantHouseholdId = data ? data.id : null;
      //console.error(this.importSetting.data);
    },
  },
  created() {
    this.load();
  },
  data() {
    return {
      options: {
        key: "",
        peasantHouseholdKey: "",
        staffKey: "",
        adminKey: "",
        startTime: "",
        endTime: "",
        page: 1,
        pageSize: 10,
      },
      startTimeEnabled: false,
      endTimeEnabled: false,
      table: {
        page: {},
        loading: false,
        headers: [
          {
            text: "编码",
            sortable: false,
            value: "number",
            width: 250,
          },
          {
            text: "农户信息",
            sortable: false,
            value: "peasantHousehold",
          },
          {
            text: "创建者",
            sortable: false,
            value: "account",
          },
          {
            text: "创建日期",
            sortable: false,
            align: "center",
            value: "created",
            width: 170,
          },
          {
            text: "操作",
            sortable: false,
            align: "center",
            value: "actions",
            width: 130,
          },
        ],
        deteleItem: null,
        deleteDialog: false,
      },
      exportSetting: {
        dialog: false,
        singleWorksheet: false,
      },
      importSetting: {
        dialog: false,
        file: {
          file: null,
          rules: [(v) => !!v || "请添加要导入的文件"],
        },
        data: {
          file: null,
          peasantHouseholdId: null,
        },
        peasantHousehold: {
          loading: false,
          value: "",
          search: "",
          items: [],
          data: [],
          rules: [(v) => !!v || "请选择农户"],
        },
        result: {
          show: false,
          success: false,
          message: false,
        },
        valid: false,
      },
    };
  },
};
</script>
<style lang="less" scoped>
</style>
